import React from "react"; 
import {Link} from "react-router-dom";   
import Team from "../Components/Team";
import ServiceC from "../Components/ServiceC";
import ProjectC from "../Components/ProjectC";
import ContactC from "../Components/ContactC";
import AboutC from "../Components/AboutC";

function Home(){
    return(
<>

<div className="main-wrapper">
      
  <div className="container-fluid px-0">
      <div id="carouselId" className="carousel slide" data-bs-ride="carousel">
          <ol className="carousel-indicators">
              <li data-bs-target="#carouselId" data-bs-slide-to="0" className="active" aria-current="true" aria-label="First slide"></li>
              <li data-bs-target="#carouselId" data-bs-slide-to="1" aria-label="Second slide"></li>
          </ol>
          <div className="carousel-inner" role="listbox">
              <div className="carousel-item active">
                  <img src="img/banner1.jpg" className="img-fluid" alt="First slide" />
                  <div className="carousel-caption">
                      <div className="container carousel-content">
                          <h6 className="text-secondary h4 animated fadeInUp">Best IT Solutions</h6>
                          <h1 className="text-white display-1 mb-4 animated fadeInRight">An Innovative IT Solutions Agency</h1>
                          <p className="mb-4 text-white fs-5 animated fadeInDown">Transform your ideas into a visually stunning and user-friendly website, tailored to meet the unique needs of your business.</p> 
                          <Link to="/about" className="me-2"><button type="button" className="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn1 animated fadeInLeft">Read More</button></Link>
                          <Link to="/contact" className="ms-2"><button type="button" className="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn2 animated fadeInRight">Contact Us</button></Link>
                      </div>
                  </div>
              </div>
              <div className="carousel-item"> 
                  <img src="img/banner2.jpg" className="img-fluid" alt="Second slide" />
                  <div className="carousel-caption">
                      <div className="container carousel-content">
                          <h6 className="text-secondary h4 animated fadeInUp">Best IT Solutions</h6>
                          <h1 className="text-white display-1 mb-4 animated fadeInLeft">Quality Digital Services You Really Need!</h1>
                          <p className="mb-4 text-white fs-5 animated fadeInDown">Revolutionize your online presence with Code IT Solutions – where cutting-edge technology meets unparalleled creativity.</p>   
                          <Link to="/about" className="me-2"><button type="button" className="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn1 animated fadeInLeft">Read More</button></Link>
                          <Link to="/contact" className="ms-2"><button type="button" className="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn2 animated fadeInRight">Contact Us</button></Link>
                      </div>
                  </div>
              </div>
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselId" data-bs-slide="prev">
                      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span className="visually-hidden">Previous</span>
                  </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselId" data-bs-slide="next">
                      <span className="carousel-control-next-icon" aria-hidden="true"></span>
                      <span className="visually-hidden">Next</span>
                  </button> 
      </div>
  </div>
  
  
  {/* <AboutC /> */}
  <div className="container-fluid py-5 my-5">
            <div className="container pt-5">
                <div className="row g-5">
                    <div className="col-lg-5 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".3s">
                        <div className="h-100 position-relative">
                            <img src="img/about2.jpg" className="img-fluid w-75 rounded" alt="" style={{marginBottom: '25%'}} />
                            <div className="position-absolute w-75" style={{top: '25%', left: '25%'}}>
                                <img src="img/about1.jpg" className="img-fluid w-100 rounded" alt="" />
                            </div> 
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".5s">
                        <h5 className="text-primary">About Us</h5>
                        <h2 className="mb-4"> Code IT Solutions It's Innovative Agency</h2>  
                        <p>Code IT Solutions is a cutting-edge and innovative agency at the forefront of technological advancement. Specializing in providing comprehensive IT solutions, we pride ourselves on delivering tailored services that address the unique needs of our clients. Our team of skilled professionals is dedicated to staying ahead of the curve, leveraging the latest technologies to drive efficiency and maximize productivity for businesses across various industries. </p>
                        <p className="mb-4">With a commitment to innovation, we strive to create and implement groundbreaking solutions that not only meet but exceed the expectations of our clients. </p>  
                        <Link to="/about" className="btn btn-secondary rounded-pill px-5 py-3 text-white">View More</Link>
                    </div>
                </div>
            </div>
        </div>

  <ServiceC />
  <ProjectC />  
  
  {/* <div className="container-fluid blog py-5 mb-5">
      <div className="container">
          <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{maxWidth : '600px'}}>
              <h5 className="text-primary">Our Blog</h5>
              <h1>Latest Blog & News</h1>
          </div>
          <div className="row g-5 justify-content-center">
              <div className="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".3s">
                  <div className="blog-item position-relative bg-light rounded">
                      <img src="img/blog-1.jpg" className="img-fluid w-100 rounded-top" alt="" />
                      <span className="position-absolute px-4 py-3 bg-primary text-white rounded" style={{top: '-28px', right: '20px'}}>Web Design</span>
                      <div className="blog-btn d-flex justify-content-between position-relative px-3" style={{marginTop : '-75px'}}>
                          <div className="blog-icon btn btn-secondary px-3 rounded-pill my-auto">
                              <a href="" className="btn text-white">Read More</a>
                          </div>
                          <div className="blog-btn-icon btn btn-secondary px-4 py-3 rounded-pill ">
                              <div className="blog-icon-1">
                                  <p className="text-white px-2">Share<i className="fa fa-arrow-right ms-3"></i></p>
                              </div>
                              <div className="blog-icon-2">
                                  <a href="" className="btn me-1"><i className="fab fa-facebook-f text-white"></i></a>
                                  <a href="" className="btn me-1"><i className="fab fa-twitter text-white"></i></a>
                                  <a href="" className="btn me-1"><i className="fab fa-instagram text-white"></i></a>
                              </div>
                          </div>
                      </div>
                      <div className="blog-content text-center position-relative px-3" style={{marginTop : '-25px'}}>
                          <img src="img/admin.jpg" className="img-fluid rounded-circle border border-4 border-white mb-3" alt="" />
                          <h5 className="">By Daniel Martin</h5>
                          <span className="text-secondary">24 March 2023</span>
                          <p className="py-2">Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut interdum. Aliquam dolor eget urna ultricies tincidunt libero sit amet</p>
                      </div>
                      <div className="blog-coment d-flex justify-content-between px-4 py-2 border bg-primary rounded-bottom">
                          <a href="" className="text-white"><small><i className="fas fa-share me-2 text-secondary"></i>5324 Share</small></a>
                          <a href="" className="text-white"><small><i className="fa fa-comments me-2 text-secondary"></i>5 Comments</small></a>
                      </div>
                  </div>
              </div>
              <div className="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".5s">
                  <div className="blog-item position-relative bg-light rounded">
                      <img src="img/blog-2.jpg" className="img-fluid w-100 rounded-top" alt="" />
                      <span className="position-absolute px-4 py-3 bg-primary text-white rounded" style={{top: '-28px', right: '20px'}}>Development</span>
                      <div className="blog-btn d-flex justify-content-between position-relative px-3" style={{marginTop : '-75px'}}>
                          <div className="blog-icon btn btn-secondary px-3 rounded-pill my-auto">
                              <a href="" className="btn text-white ">Read More</a>
                          </div>
                          <div className="blog-btn-icon btn btn-secondary px-4 py-3 rounded-pill ">
                              <div className="blog-icon-1">
                                  <p className="text-white px-2">Share<i className="fa fa-arrow-right ms-3"></i></p>
                              </div>
                              <div className="blog-icon-2">
                                  <a href="" className="btn me-1"><i className="fab fa-facebook-f text-white"></i></a>
                                  <a href="" className="btn me-1"><i className="fab fa-twitter text-white"></i></a>
                                  <a href="" className="btn me-1"><i className="fab fa-instagram text-white"></i></a>
                              </div>
                          </div>
                      </div>
                      <div className="blog-content text-center position-relative px-3" style={{marginTop : '-25px'}}>
                          <img src="img/admin.jpg" className="img-fluid rounded-circle border border-4 border-white mb-3" alt="" />
                          <h5 className="">By Daniel Martin</h5>
                          <span className="text-secondary">23 April 2023</span>
                          <p className="py-2">Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut interdum. Aliquam dolor eget urna ultricies tincidunt libero sit amet</p>
                      </div>
                      <div className="blog-coment d-flex justify-content-between px-4 py-2 border bg-primary rounded-bottom">
                          <a href="" className="text-white"><small><i className="fas fa-share me-2 text-secondary"></i>5324 Share</small></a>
                          <a href="" className="text-white"><small><i className="fa fa-comments me-2 text-secondary"></i>5 Comments</small></a>
                      </div>
                  </div>
              </div>
              <div className="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".7s">
                  <div className="blog-item position-relative bg-light rounded">
                      <img src="img/blog-3.jpg" className="img-fluid w-100 rounded-top" alt="" />
                      <span className="position-absolute px-4 py-3 bg-primary text-white rounded" style={{top: '-28px', right: '20px'}}>Mobile App</span>
                      <div className="blog-btn d-flex justify-content-between position-relative px-3" style={{marginTop : '-75px'}}>
                          <div className="blog-icon btn btn-secondary px-3 rounded-pill my-auto">
                              <a href="" className="btn text-white ">Read More</a>
                          </div>
                          <div className="blog-btn-icon btn btn-secondary px-4 py-3 rounded-pill ">
                              <div className="blog-icon-1">
                                  <p className="text-white px-2">Share<i className="fa fa-arrow-right ms-3"></i></p>
                              </div>
                              <div className="blog-icon-2">
                                  <a href="" className="btn me-1"><i className="fab fa-facebook-f text-white"></i></a>
                                  <a href="" className="btn me-1"><i className="fab fa-twitter text-white"></i></a>
                                  <a href="" className="btn me-1"><i className="fab fa-instagram text-white"></i></a>
                              </div>
                          </div>
                      </div>
                      <div className="blog-content text-center position-relative px-3" style={{marginTop : '-25px'}}>
                          <img src="img/admin.jpg" className="img-fluid rounded-circle border border-4 border-white mb-3" alt="" />
                          <h5 className="">By Daniel Martin</h5>
                          <span className="text-secondary">30 jan 2023</span>
                          <p className="py-2">Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut interdum. Aliquam dolor eget urna ultricies tincidunt libero sit amet</p>
                      </div>
                      <div className="blog-coments d-flex justify-content-between px-4 py-2 border bg-primary rounded-bottom">
                          <a href="" className="text-white"><small><i className="fas fa-share me-2 text-secondary"></i>5324 Share</small></a>
                          <a href="" className="text-white"><small><i className="fa fa-comments me-2 text-secondary"></i>5 Comments</small></a>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div> */} 
  
  {/* <Team />   */}
  
  
  
  {/* testimonial */}
  {/* <div class="container-fluid testimonial py-5 mb-5">
              <div class="container">
                  <div class="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{maxWidth: '600px', visibility: 'hidden', animationDelay: '0.3s', animationName: 'none'}}>
                      <h5 class="text-primary">Our Testimonial</h5> 
                      <h1>Our Client Saying!</h1>
                  </div>
                  <div class="owl-carousel testimonial-carousel wow fadeIn owl-loaded owl-drag" data-wow-delay=".5s" style={{visibility: 'hidden', animationDelay: '0.5s', animationName: 'none'}}>
                      
                      
                  <div class="owl-stage-outer"><div class="owl-stage" style={{transition: 'all 1.5s ease 0s', width: '4320px', transform: 'translate3d(-1296px, 0px, 0px)'}}><div class="owl-item cloned" style={{width: '432px'}}><div class="testimonial-item border p-4">
                          <div class=" d-flex align-items-center">
                              <div class="">
                                  <img src="img/testimonial-2.jpg" alt="" />
                              </div>
                              <div class="ms-4">
                                  <h4 class="text-secondary">Client Name</h4>
                                  <p class="m-0 pb-3">Profession</p>
                                  <div class="d-flex pe-5">
                                      <i class="fas fa-star me-1 text-primary"></i>
                                      <i class="fas fa-star me-1 text-primary"></i>
                                      <i class="fas fa-star me-1 text-primary"></i>
                                      <i class="fas fa-star me-1 text-primary"></i>
                                      <i class="fas fa-star me-1 text-primary"></i>
                                  </div>
                              </div>
                          </div>
                          <div class="border-top mt-4 pt-3">
                              <p class="mb-0">Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut interdum aliquam dolor eget urna. Nam volutpat libero sit amet leo cursus, ac viverra eros morbi quis quam mi.</p>
                          </div>
                      </div></div><div class="owl-item cloned" style={{width: '432px'}}><div class="testimonial-item border p-4">
                          <div class=" d-flex align-items-center">
                              <div class="">
                                  <img src="img/testimonial-3.jpg" alt="" />
                              </div>
                              <div class="ms-4">
                                  <h4 class="text-secondary">Client Name</h4>
                                  <p class="m-0 pb-3">Profession</p>
                                  <div class="d-flex pe-5">
                                      <i class="fas fa-star me-1 text-primary"></i>
                                      <i class="fas fa-star me-1 text-primary"></i>
                                      <i class="fas fa-star me-1 text-primary"></i>
                                      <i class="fas fa-star me-1 text-primary"></i>
                                      <i class="fas fa-star me-1 text-primary"></i>
                                  </div>
                              </div>
                          </div>
                          <div class="border-top mt-4 pt-3">
                              <p class="mb-0">Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut interdum aliquam dolor eget urna. Nam volutpat libero sit amet leo cursus, ac viverra eros morbi quis quam mi.</p>
                          </div>
                      </div></div><div class="owl-item cloned" style={{width: '432px'}}><div class="testimonial-item border p-4">
                          <div class=" d-flex align-items-center">
                              <div class="">
                                  <img src="img/testimonial-4.jpg" alt="" />
                              </div>
                              <div class="ms-4">
                                  <h4 class="text-secondary">Client Name</h4>
                                  <p class="m-0 pb-3">Profession</p>
                                  <div class="d-flex pe-5">
                                      <i class="fas fa-star me-1 text-primary"></i>
                                      <i class="fas fa-star me-1 text-primary"></i>
                                      <i class="fas fa-star me-1 text-primary"></i>
                                      <i class="fas fa-star me-1 text-primary"></i>
                                      <i class="fas fa-star me-1 text-primary"></i>
                                  </div>
                              </div>
                          </div>
                          <div class="border-top mt-4 pt-3">
                              <p class="mb-0">Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut interdum aliquam dolor eget urna. Nam volutpat libero sit amet leo cursus, ac viverra eros morbi quis quam mi.</p>
                          </div>
                      </div></div><div class="owl-item active" style={{width: '432px'}}><div class="testimonial-item border p-4">
                          <div class="d-flex align-items-center">
                              <div class="">
                                  <img src="img/testimonial-1.jpg" alt="" />
                              </div>
                              <div class="ms-4">
                                  <h4 class="text-secondary">Client Name</h4>
                                  <p class="m-0 pb-3">Profession</p>
                                  <div class="d-flex pe-5">
                                      <i class="fas fa-star me-1 text-primary"></i>
                                      <i class="fas fa-star me-1 text-primary"></i>
                                      <i class="fas fa-star me-1 text-primary"></i>
                                      <i class="fas fa-star me-1 text-primary"></i>
                                      <i class="fas fa-star me-1 text-primary"></i>
                                  </div>
                              </div>
                          </div>
                          <div class="border-top mt-4 pt-3">
                              <p class="mb-0">Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut interdum aliquam dolor eget urna. Nam volutpat libero sit amet leo cursus, ac viverra eros morbi quis quam mi.</p>
                          </div>
                      </div></div><div class="owl-item active center" style={{width: '432px'}}><div class="testimonial-item border p-4">
                          <div class=" d-flex align-items-center">
                              <div class="">
                                  <img src="img/testimonial-2.jpg" alt="" />
                              </div>
                              <div class="ms-4">
                                  <h4 class="text-secondary">Client Name</h4>
                                  <p class="m-0 pb-3">Profession</p>
                                  <div class="d-flex pe-5">
                                      <i class="fas fa-star me-1 text-primary"></i>
                                      <i class="fas fa-star me-1 text-primary"></i>
                                      <i class="fas fa-star me-1 text-primary"></i>
                                      <i class="fas fa-star me-1 text-primary"></i>
                                      <i class="fas fa-star me-1 text-primary"></i>
                                  </div>
                              </div>
                          </div>
                          <div class="border-top mt-4 pt-3">
                              <p class="mb-0">Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut interdum aliquam dolor eget urna. Nam volutpat libero sit amet leo cursus, ac viverra eros morbi quis quam mi.</p>
                          </div>
                      </div></div><div class="owl-item active" style={{width: '432px'}}><div class="testimonial-item border p-4">
                          <div class=" d-flex align-items-center">
                              <div class="">
                                  <img src="img/testimonial-3.jpg" alt="" />
                              </div>
                              <div class="ms-4">
                                  <h4 class="text-secondary">Client Name</h4>
                                  <p class="m-0 pb-3">Profession</p>
                                  <div class="d-flex pe-5">
                                      <i class="fas fa-star me-1 text-primary"></i>
                                      <i class="fas fa-star me-1 text-primary"></i>
                                      <i class="fas fa-star me-1 text-primary"></i>
                                      <i class="fas fa-star me-1 text-primary"></i>
                                      <i class="fas fa-star me-1 text-primary"></i>
                                  </div>
                              </div>
                          </div>
                          <div class="border-top mt-4 pt-3">
                              <p class="mb-0">Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut interdum aliquam dolor eget urna. Nam volutpat libero sit amet leo cursus, ac viverra eros morbi quis quam mi.</p>
                          </div>
                      </div></div><div class="owl-item" style={{width: '432px'}}><div class="testimonial-item border p-4">
                          <div class=" d-flex align-items-center">
                              <div class="">
                                  <img src="img/testimonial-4.jpg" alt="" />
                              </div>
                              <div class="ms-4">
                                  <h4 class="text-secondary">Client Name</h4>
                                  <p class="m-0 pb-3">Profession</p>
                                  <div class="d-flex pe-5">
                                      <i class="fas fa-star me-1 text-primary"></i>
                                      <i class="fas fa-star me-1 text-primary"></i>
                                      <i class="fas fa-star me-1 text-primary"></i>
                                      <i class="fas fa-star me-1 text-primary"></i>
                                      <i class="fas fa-star me-1 text-primary"></i>
                                  </div>
                              </div>
                          </div>
                          <div class="border-top mt-4 pt-3">
                              <p class="mb-0">Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut interdum aliquam dolor eget urna. Nam volutpat libero sit amet leo cursus, ac viverra eros morbi quis quam mi.</p>
                          </div>
                      </div></div><div class="owl-item cloned" style={{width: '432px'}}><div class="testimonial-item border p-4">
                          <div class="d-flex align-items-center">
                              <div class="">
                                  <img src="img/testimonial-1.jpg" alt="" />
                              </div>
                              <div class="ms-4">
                                  <h4 class="text-secondary">Client Name</h4>
                                  <p class="m-0 pb-3">Profession</p>
                                  <div class="d-flex pe-5">
                                      <i class="fas fa-star me-1 text-primary"></i>
                                      <i class="fas fa-star me-1 text-primary"></i>
                                      <i class="fas fa-star me-1 text-primary"></i>
                                      <i class="fas fa-star me-1 text-primary"></i>
                                      <i class="fas fa-star me-1 text-primary"></i>
                                  </div>
                              </div>
                          </div>
                          <div class="border-top mt-4 pt-3">
                              <p class="mb-0">Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut interdum aliquam dolor eget urna. Nam volutpat libero sit amet leo cursus, ac viverra eros morbi quis quam mi.</p>
                          </div>
                      </div></div><div class="owl-item cloned" style={{width: '432px'}}><div class="testimonial-item border p-4">
                          <div class=" d-flex align-items-center">
                              <div class="">
                                  <img src="img/testimonial-2.jpg" alt="" />
                              </div>
                              <div class="ms-4">
                                  <h4 class="text-secondary">Client Name</h4>
                                  <p class="m-0 pb-3">Profession</p>
                                  <div class="d-flex pe-5">
                                      <i class="fas fa-star me-1 text-primary"></i>
                                      <i class="fas fa-star me-1 text-primary"></i>
                                      <i class="fas fa-star me-1 text-primary"></i>
                                      <i class="fas fa-star me-1 text-primary"></i>
                                      <i class="fas fa-star me-1 text-primary"></i>
                                  </div>
                              </div>
                          </div>
                          <div class="border-top mt-4 pt-3">
                              <p class="mb-0">Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut interdum aliquam dolor eget urna. Nam volutpat libero sit amet leo cursus, ac viverra eros morbi quis quam mi.</p>
                          </div>
                      </div></div><div class="owl-item cloned" style={{width: '432px'}}><div class="testimonial-item border p-4">
                          <div class=" d-flex align-items-center">
                              <div class="">
                                  <img src="img/testimonial-3.jpg" alt="" />
                              </div>
                              <div class="ms-4">
                                  <h4 class="text-secondary">Client Name</h4>
                                  <p class="m-0 pb-3">Profession</p>
                                  <div class="d-flex pe-5">
                                      <i class="fas fa-star me-1 text-primary"></i>
                                      <i class="fas fa-star me-1 text-primary"></i>
                                      <i class="fas fa-star me-1 text-primary"></i>
                                      <i class="fas fa-star me-1 text-primary"></i>
                                      <i class="fas fa-star me-1 text-primary"></i>
                                  </div>
                              </div>
                          </div>
                          <div class="border-top mt-4 pt-3">
                              <p class="mb-0">Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut interdum aliquam dolor eget urna. Nam volutpat libero sit amet leo cursus, ac viverra eros morbi quis quam mi.</p>
                          </div>
                      </div></div></div></div><div class="owl-nav"><div class="owl-prev"></div><div class="owl-next"></div></div><div class="owl-dots"><div class="owl-dot"><span></span></div><div class="owl-dot active"><span></span></div><div class="owl-dot"><span></span></div><div class="owl-dot"><span></span></div></div></div> 
              </div>
          </div> */}

    
  
        <ContactC /> 

        </div>
    </> 
    );
}

export default Home;