import {Link} from "react-router-dom";  

function ProjectC(){
    return(
        <>
         
            <div id="portfolio" className="container-fluid project py-5 mb-5 portfolio_wrapper">

            {/* <div className="container">
                <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{maxWidth : '600px'}}>
                    <h5 className="text-primary">Our Project</h5>
                    <h1>Our Recently Projects</h1>
                </div>
                <div className="row g-5">
                    <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
                        <div className="project-item">
                            <div className="project-img">
                                <img src="img/project-1.jpg" className="img-fluid w-100 rounded" alt="" />
                                <div className="project-content">
                                    <a href="#" className="text-center">
                                        <h4 className="text-secondary">Web design2</h4>
                                        <p className="m-0 text-white">Web Analysis2</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
                        <div className="project-item">
                            <div className="project-img">
                                <img src="img/project-2.jpg" className="img-fluid w-100 rounded" alt="" />
                                <div className="project-content">
                                    <a href="#" className="text-center">
                                        <h4 className="text-secondary">Web Security</h4>
                                        <p className="m-0 text-white">Web Security Core</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
                        <div className="project-item">
                            <div className="project-img">
                                <img src="img/project-3.jpg" className="img-fluid w-100 rounded" alt="" />
                                <div className="project-content">
                                    <a href="#" className="text-center">
                                        <h4 className="text-secondary">Mobile Info</h4>
                                        <p className="m-0 text-white">Upcomming Phone</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
                        <div className="project-item">
                            <div className="project-img">
                                <img src="img/project-4.jpg" className="img-fluid w-100 rounded" alt="" />
                                <div className="project-content">
                                    <a href="#" className="text-center">
                                        <h4 className="text-secondary">Web Development</h4>
                                        <p className="m-0 text-white">Web Analysis</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
                        <div className="project-item">
                            <div className="project-img">
                                <img src="img/project-5.jpg" className="img-fluid w-100 rounded" alt="" />
                                <div className="project-content">
                                    <a href="#" className="text-center">
                                        <h4 className="text-secondary">Digital Marketing</h4>
                                        <p className="m-0 text-white">Marketing Analysis</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
                        <div className="project-item">
                            <div className="project-img">
                                <img src="img/project-6.jpg" className="img-fluid w-100 rounded" alt="" />
                                <div className="project-content">
                                    <a href="#" className="text-center">
                                        <h4 className="text-secondary">keyword Research</h4>
                                        <p className="m-0 text-white">keyword Analysis</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="container">
            <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{maxWidth : '600px'}}>
                    <h5 className="text-primary">Our Project</h5>
                    <h1>Our Recently Projects</h1>
            </div>
            <div className="row g-5"> 
                <div className="col-lg-3 col-md-6 mb-4">
                <a href="#" target="_blank"> 
                    <div className="card p-0">
                        <span style={{backgroundImage: 'url("./img/portfolio/Foodies.png")'}} />
                    </div>
                </a>
                </div>
                <div className="col-lg-3 col-md-6 mb-4">
                <a href="#" target="_blank"> 
                    <div className="card p-0">
                        <span style={{backgroundImage: 'url("./img/portfolio/App-Landing-Page.png")'}} />
                    </div>
                </a>
                </div>
                <div className="col-lg-3 col-md-6 mb-4">
                <a href="#" target="_blank"> 
                    <div className="card p-0">
                        <span style={{backgroundImage: 'url("./img/portfolio/E-Commerce-Website-Using-Bootstap-5.png")'}} />
                    </div>
                </a> 
                </div>
                <div className="col-lg-3 col-md-6 mb-4">
                <a href="#" target="_blank">
                    <div className="card p-0">
                        <span style={{backgroundImage: 'url("./img/portfolio/HeadPhones.png")'}} />
                    </div>
                </a> 
                </div>
                <div className="col-lg-3 col-md-6 mb-4">
                <a href="#" target="_blank">
                    <div className="card p-0">
                        <span style={{backgroundImage: 'url("./img/portfolio/Ecommerce-Jewellery-Website.png")'}} />
                    </div>
                </a>
                </div>
                <div className="col-lg-3 col-md-6 mb-4">
                <a href="#" target="_blank">
                    <div className="card p-0">
                        <span style={{backgroundImage: 'url("./img/portfolio/Hashtag-Landing-Page.png")'}} />
                    </div>
                </a>
                </div>
                <div className="col-lg-3 col-md-6 mb-4">
                <a href="#" target="_blank">
                    <div className="card p-0">
                        <span style={{backgroundImage: 'url("./img/portfolio/Christmas-Landing-Page.png")'}} />
                    </div>
                </a>
                </div>
                <div className="col-lg-3 col-md-6 mb-4">
                <a href="#" target="_blank">
                    <div className="card p-0">
                        <span style={{backgroundImage: 'url("./img/portfolio/SEO-Landing-Page.png")'}} />
                    </div>
                </a>

                {/* <div className="col-lg-12 col-md-6 mb-4 align-items-center">
                    <Link to="/projects" className="btn btn-secondary rounded-pill px-5 py-3 text-white">View More</Link>
                </div> */} 
                
                </div> 

                

            </div>
            </div>


            </div> 

        </>
    );
}

export default ProjectC;