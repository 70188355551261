import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

const DropdownMenu = () => {
  const location = useLocation();
  const [activeItem, setActiveItem] = useState("");

  useEffect(() => {
    setActiveItem(location.pathname);
  }, [location]);

  const menuItems = [
    { name: "About Us", to: "/about" }, 
    // { name: "Blog", to: "" },
    // { name: "Our Team", to: "" }, 
    { name: "Pay Now", to: "/PayNow" } // No link for now
  ];

  return (
    <div className="nav-item dropdown"> 
      <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">IT Solutions</a>
      <div className="dropdown-menu rounded">
        {menuItems.map((item, index) => (
          item.to ? (
            <NavLink
              key={index}
              className={`dropdown-item ${activeItem === item.to ? "active" : ""}`}
              to={item.to}
              onClick={() => setActiveItem(item.to)}
            >
              {item.name}
            </NavLink>
          ) : (
            <div
              key={index}
              className={`dropdown-item ${activeItem === item.name ? "active" : ""}`}
              onClick={() => setActiveItem(item.name)}
            >
              {item.name}
            </div>
          )
        ))}
      </div>
    </div>
  );
};

export default DropdownMenu;
