import React, { useState } from "react";
import { Link } from "react-router-dom";

function PayNow() {
  // State to handle the visibility of the payment options
  const [showBankDetails, setShowBankDetails] = useState(false);
  const [showMobileDetails, setShowMobileDetails] = useState(false);

  // Function to toggle bank details
  const toggleBankDetails = () => {
    setShowBankDetails(!showBankDetails);
    setShowMobileDetails(false); // Close mobile details if bank is opened
  };

  // Function to toggle mobile details
  const toggleMobileDetails = () => {
    setShowMobileDetails(!showMobileDetails);
    setShowBankDetails(false); // Close bank details if mobile is opened
  };

  return (
    <>
      <div className="container-fluid page-header py-5">
        <div className="container text-center py-5">
          <h1 className="display-2 text-white mb-4 animated slideInDown">Pay Now</h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item" aria-current="page" style={{ color: '#FDA403' }}>Pay Now</li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="container-fluid py-5 mb-5">
        <div className="container">
          <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{ maxWidth: '600px' }}>
            <h5 className="text-primary">Payment Services</h5>
            <h1 className="text-dark">Secure and Fast Online Payments</h1>
          </div>
          <div className="row g-5">
            <div className="col-12 wow fadeIn" data-wow-delay=".3s">
              <div className="bg-light p-5">
                <h4 className="mb-3"><strong>Bank Transfer Details</strong></h4> {/* Make it bold */}
                <ul>
                  <li>
                    <button 
                      className="btn btn-link" 
                      onClick={toggleBankDetails} // Toggle bank details
                    >
                      Bank Transfers
                    </button>
                  </li>
                  <li>
                    <button 
                      className="btn btn-link" 
                      onClick={toggleMobileDetails} // Toggle mobile payment details
                    >
                      Mobile Payment Options
                    </button>
                  </li>
                </ul>

                {showBankDetails && ( // Conditionally render bank details
                  <div className="bank-form mt-5">
                    <table className="table table-bordered">
                      <tbody>
                        <tr>
                          <td><strong>Bank Name</strong></td>
                          <td>HDFC</td>
                        </tr>
                        <tr>
                          <td><strong>Account Number</strong></td>
                          <td>50100285063995</td>
                        </tr>
                        <tr>
                          <td><strong>IFSC Code</strong></td>
                          <td>HDFC0001351</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}

                {showMobileDetails && ( // Conditionally render mobile payment details
                  <div className="mobile-form mt-5">
                    <h4 className="mb-3">Mobile Payment Via PhonePe</h4>
                    <div className="mb-3">
                      <p>PhonePe QR</p>
                    </div>
                    <div className="mb-3">
                      <img 
                        src="img/pay.jpg" 
                        alt="PhonePe QR Code" 
                        className="img-fluid qr-code"
                        // style={{ width: '800px', height: '500px' }}
                        style={{ width: '850px'}} 
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PayNow;
