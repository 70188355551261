import React from "react";
import { Link } from "react-router-dom";
import ProjectC from "../Components/ProjectC";

function Webdesign() {
  return (
    <> 
      <div class="container-fluid page-header py-5">
          <div class="container text-center py-5">
              <h1 class="display-2 text-white mb-4 animated slideInDown">WebDesign</h1>
              <nav aria-label="breadcrumb animated slideInDown">
                  <ol class="breadcrumb justify-content-center mb-0">
                      <li class="breadcrumb-item"><Link to="/">Home</Link></li>
                      <li class="breadcrumb-item" aria-current="page" style={{color: '#FDA403 '}}>WebDesign</li>
                  </ol>
              </nav> 
          </div>
      </div> 
      <div className="container-fluid py-5 mb-5">
        <div className="container">
          <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{ maxWidth: '600px' }}>
            <h5 className="text-primary">Web Design</h5>
            <h1 className="text-dark">Creating Beautiful and Functional Websites</h1>
          </div>
          <div className="row g-5">
            <div className="col-12 wow fadeIn" data-wow-delay=".3s">
              <div className="bg-light p-5">
                <h4 className="mb-3">Our Web Design Services</h4>
                <p>We specialize in creating visually appealing and functionally seamless websites that adapt flawlessly to every device. Our design process is rooted in user experience and aesthetics, ensuring that your website not only looks great but also works perfectly on all platforms.</p>
                <p>We offer:</p>
                <ul>
                  <li>Custom Web Design</li>
                  <li>Responsive Design</li>
                  <li>E-commerce Design</li>
                  <li>Website Redesign</li>
                </ul>
                <Link to="/contact" className="btn btn-secondary rounded-pill px-5 py-3 text-white">Get in Touch</Link>
              </div>
            </div>
          </div>
        </div>
        <ProjectC />  
      </div>
      
    </>
  );
}

export default Webdesign;
