import React from "react";
import { Link } from "react-router-dom";
import ProjectC from "../Components/ProjectC";

function MobileApp() {
  return (
    <> 
      <div className="container-fluid page-header py-5">
          <div className="container text-center py-5">
              <h1 className="display-2 text-white mb-4 animated slideInDown">Mobile Application</h1>
              <nav aria-label="breadcrumb animated slideInDown">
                  <ol className="breadcrumb justify-content-center mb-0">
                      <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                      <li className="breadcrumb-item" aria-current="page" style={{color: '#FDA403 '}}>Mobile Application</li>
                  </ol>
              </nav> 
          </div>
      </div> 
      <div className="container-fluid py-5 mb-5">
        <div className="container">
          <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{ maxWidth: '600px' }}>
            <h5 className="text-primary">Mobile Application</h5>
            <h1 className="text-dark">Developing High-Quality Mobile Apps for All Platforms</h1>
          </div>
          <div className="row g-5">
            <div className="col-12 wow fadeIn" data-wow-delay=".3s">
              <div className="bg-light p-5">
                <h4 className="mb-3">Our Mobile App Development Services</h4>
                <p>We provide expert mobile app development services to bring your ideas to life. Our solutions are tailored to meet your business goals, offering a seamless experience on all mobile platforms.</p>
                <p>We offer:</p>
                <ul>
                  <li>Custom Mobile App Development</li>
                  <li>iOS & Android Development</li>
                  <li>Cross-Platform Development</li>
                  <li>App Maintenance & Support</li>
                </ul>
                <Link to="/contact" className="btn btn-secondary rounded-pill px-5 py-3 text-white">Get in Touch</Link>
              </div>
            </div>
          </div>
        </div>
        <ProjectC />  
      </div>
    </>
  );
}

export default MobileApp;
