import React from "react";
import ProjectC from "../Components/ProjectC";
import {Link} from "react-router-dom"; 

function Projects(){
    return(
        <>
            <div class="container-fluid page-header py-5">
                <div class="container text-center py-5">
                    <h1 class="display-2 text-white mb-4 animated slideInDown">Projects</h1>
                    <nav aria-label="breadcrumb animated slideInDown">
                        <ol class="breadcrumb justify-content-center mb-0">
                            <li class="breadcrumb-item"><Link to="/">Home</Link></li> 
                            <li class="breadcrumb-item" aria-current="page" style={{color: '#FDA403 '}}>Projects</li>
                        </ol>
                    </nav>
                </div>
            </div> 
            <ProjectC /> 
        </>
    );
}

export default Projects; 